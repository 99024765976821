import { Link, useParams } from 'react-router-dom'
import { getDatabase, ref, set, onValue, get, child } from "firebase/database";
import logo from './logo.png';
import { useEffect, useState } from 'react';

import './App.css';
import { ref as referff, getStorage, child as child2, getDownloadURL } from 'firebase/storage'
import app from './firebase_conf';

function Person() {



    // the data from the link
    const { info } = useParams();
    const [socialAccounts, setSocialAccounts] = useState();
    const userId = info;
    const dbRef = ref(getDatabase());
    var [userData, setUserData] = useState();


    const [accountlist, setAccountlist] = useState();
    const [facebookAccount, setFacebookAccount] = useState();
    const [whatsappAccount, setWhatsappAccount] = useState();
    // const imagesUlr = refref(getStorage, `/upload/${userId}.png`)
    const [tiktokAccount, setTiktokAccount] = useState();
    const [linkedinAccount, setLinkedinAccount] = useState();
    const [twitterAccount, setTwitterAccount] = useState();
    const [snapchatAccount, setSnapchatAccount] = useState();
    const [youtubeAccout, setYoutubeAccount] = useState();
    const [userName, setUserName] = useState();
    const [imageUrl, setImageurl] = useState();
    // const storage = getStorage(app);
    var test = `uploads/profile_pic_${info}.png`
    const storage = getStorage();
    getDownloadURL(referff(storage, test))
        .then((url) => {
            console.log(url);
            setImageurl(url);
        })
        .catch((error) => {

        });

    // const imageUrl = referff(storage,test )

    // storage.ref('uploads').child(`profile_pic_${info}.png`).getDownloadURL().then((url) =>{console.log(url);})
    // console.log(imageUrl)
    get(child(dbRef, `Users/${userId}`)).then((snapshot) => {
        if (snapshot.exists()) {
            // console.log(snapshot.val());
            // setSocialAccounts(Object.keys(snapshot.val()["socialAccounts"]));
            var data = snapshot.val();

            setUserName(data["firstname"])
            if (data.socialAccounts.facebook)
                setFacebookAccount(snapshot.val()["socialAccounts"]["facebook"]);
            if (data.socialAccounts.whatsapp)
                setWhatsappAccount(snapshot.val()["socialAccounts"]["whatsapp"]);
            if (data.socialAccounts.tiktok)
                setTiktokAccount(snapshot.val()["socialAccounts"]["tiktok"]);
            if (data.socialAccounts.linkedin)
                setLinkedinAccount(snapshot.val()["socialAccounts"]["linkedin"]);
            if (data.socialAccounts.twitter) {
                setTwitterAccount(snapshot.val()["socialAccounts"]["twitter"]);
            }
            if (data.socialAccounts.snapchat)
                setSnapchatAccount(snapshot.val()["socialAccounts"]["snapchat"]);
            if (data.socialAccounts.youtube)
                setYoutubeAccount(snapshot.val()["socialAccounts"]["youtube"]);
            // console.log(userData);
        } else {
            console.log("No data available");
        }
    }).catch((error) => {
        console.error(error);
    });
    var testArray = [];
    function getDatab() {
        var array = [];
        try {
            array.push(facebookAccount);
            array.push(whatsappAccount);
        } catch (e) {

        }
    }
    getDatab();

    const list = ['a', 'b', 'c'];

    const SimpleList = () => (
        <ul>
            {list.map(function (item) {
                return <li key={item}>{item}</li>;
            })}
        </ul>
    );
    return (
        <div className="App">

            {/* {<h1>{name1}</h1>} */}
            {/* {<h1>{facebookAccount} , {whatsappAccount}</h1>} */}

            <header className="App-header">

                {imageUrl == null ? (<img src={logo} className="App-logo" alt="logo" />) : <img src={imageUrl} className="App-logo" alt="logo" />}


                <p>
                    {userName}
                </p>
                {/* <a>
                    Bremen
                </a> */}

                <button className='copy-button'
                    onClick={() => navigator.clipboard.writeText(`${userId}`)}
                >
                    ID in die App Kopieren 
                </button>
            </header>


        </div>


    );
}

export default Person;
