import logo from './logo.svg';
import './App.css';
import Person from './Person';
import TT from './Test12';
import {Route , BrowserRouter as Router , Switch} from 'react-router-dom';



import app from './firebase_conf';

function App() {


  return (
  <Router>
      <Switch>
        <Route exact path="/">  <TT/></Route>
      </Switch>
      <Switch>
        <Route exact path="/person/:info">  <Person/></Route>
      </Switch>
 
      
  </Router>
   );
}

export default App;



