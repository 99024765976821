import { initializeApp } from 'firebase/app';
import {getAuth} from 'firebase/auth'
import {getDatabase}  from 'firebase/database'


const firebaseConfig = {
    apiKey: "AIzaSyDhJ4ZqESax_ej89pmyYtEK9OhdKZ6Oz3I",
    authDomain: "collector-8b6da.firebaseapp.com",
    databaseURL: "https://collector-8b6da.firebaseio.com",
    projectId: "collector-8b6da",
    storageBucket: "collector-8b6da.appspot.com",
    messagingSenderId: "951380752068",
    appId: "1:951380752068:web:860eac4a3b68db7184fb91",
    measurementId: "G-402T1Y0TZH"
};

const app = initializeApp(firebaseConfig);
var auth = getAuth(app)
export default app;