import logo from './logo.svg';
import './App.css';


function TT  (){
  return (  
    
    
//     <div className="App">
//   <header className="App-header">
//     <img src={logo} className="App-logo" alt="logo" />
//     <p>
//     Gaffar
//     </p>
//     <a>
//     Bremen
//     </a>
    
//     {/* <a
//       className="App-link"
//       href="https://reactjs.org"
//       target="_blank"
//       rel="noopener noreferrer"
//     >
//       Learn React
//     </a> */}
//   </header>
 
 
 
//   <div className="ownGrid">
//   <img src= {facebook} className="App-logo-grid" alt="logo" />
//   <img src={facebook} className="App-logo-grid" alt="logo" />
//   <img src={youtube} className="App-logo-grid" alt="logo" />
//   </div>
  
//   <div className="ownGrid">
//   <img src={wahtssapp} className="App-logo-grid" alt="logo" />
//   <img src={linkedin} className="App-logo-grid" alt="logo" />
//   <img src={tiktiok} className="App-logo-grid" alt="logo" />
//   </div>
// </div>


<></>
);
}
 
export default TT;
